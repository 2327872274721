import { ApplicationError, IErrorOptions } from "~src/shared/errors/taxonomy";

export class NoTermsFoundError extends ApplicationError {
  constructor(options?: IErrorOptions) {
    super("No trading terms found.", {
      ...options,
      code: "VENDOR/INBOX/NO_TERMS_FOUND",
    });
  }
}
