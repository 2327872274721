import styled from "@emotion/styled";
import React from "react";

import { StatusTag } from "~src/designSystem/atoms/StatusTag";
import { Text } from "~src/designSystem/atoms/Text";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { Flex } from "~src/designSystem/layout/Flex";
import { Head } from "~src/designSystem/sortLater/Head";

export type IPageHeaderProps = {
  className?: string;
  /** sets the title of the page as well as heading */
  title: string;
  /** if you want to set the title but not change the tab's title  */
  pageHeading?: string;
  description?: React.ReactNode;
  /** if you want to add a tag, like "beta". if omitted, no tag will be shown.  */
  tagText?: string;
};

/** PageHeader component.
 * There should only be one per page.
 *
 * TODO(md): Can we design a better abstraction than this mishmash of implicit
 * behaviors?
 */
export const PageHeader: React.FC<IPageHeaderProps> = styled(
  ({ className, title, description, pageHeading, tagText }: IPageHeaderProps) => {
    return (
      <PleaseGoUseFlexInstead className={className} maxWidth="600px">
        <Head title={title} />
        <PleaseGoUseFlexInstead row alignItems="center">
          <Text text="titleMD">{pageHeading ?? title}</Text>
          {tagText !== undefined && (
            <Flex direction="column">
              <StatusTag color="orange">{tagText}</StatusTag>
            </Flex>
          )}
        </PleaseGoUseFlexInstead>
        {typeof description === "string" ? (
          <Text color="contentSecondary">{description}</Text>
        ) : (
          description
        )}
      </PleaseGoUseFlexInstead>
    );
  },
)``;
