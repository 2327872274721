// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import { captureException, startTransaction } from "@sentry/nextjs";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as gqlTypes from "~src/__generated__/graphql/types";

import {
  LoginRequired,
  MissingPermission,
  UnmarshalFailure,
} from "~src/shared/apigen/errors";
import * as sharedTypes from "~src/shared/apigen/types";
import { useVendorImpersonateStore } from "~src/shared/auth/store";
import {
  FieldValidationError,
  InternalServerError,
  RPCError,
  NetworkError,
  UnknownRPCError,
  IRPCErrorOptions,
} from "~src/shared/errors/taxonomy";
import { makeAPIURL } from "~src/shared/env";

export type IVendorGetTermsReturn = {
  terms: sharedTypes.IVendorTradeTerm[];
};

export const vendorGetTerms = async (
): Promise<IVendorGetTermsReturn> => {
  // Construct the headers.
  const headers: Record<string, string> = {
	"Content-Type": "application/json",
  };

  const { impersonateVendorPublicID } = useVendorImpersonateStore.getState();
  if (impersonateVendorPublicID !== null) {
    headers["X-Impersonate-Vendor"] = impersonateVendorPublicID;
  }

  // Start a Sentry transaction that wraps the HTTP request.
  const transaction = startTransaction({
    name: "pipe_rpc",
  });
  transaction.setTag("rpc.router", "vendor");
  transaction.setTag("rpc.route", "GetTerms");

  let response;
  try {
    response = await fetch(makeAPIURL() + "/v1/vendor/GetTerms", {
      method: "POST",
      cache: "no-store" as const,
      credentials: "include" as const,
      headers,
    });
  } catch (e) {
    throw new NetworkError(e?.message ?? "No error message", {
      router: "vendor",
      routePath: "GetTerms",
    });
  }

  let data;
  try {
    data = (await response.json()).data;
  } catch (e) {
    const error = new UnknownRPCError("Failed to decode JSON.", {
      cause: e,
      status: response.status,
	  data: {},
      router: "vendor",
      routePath: "GetTerms",
    });
    captureException(error);
    throw error;
  }

  transaction.setData("result.statusCode", response.status);
  transaction.finish();

  // First handle the generalized errors.
  const statusType = Math.floor(response.status / 100);
  if (statusType === 5) {
    const errorMessage = await response.text();
    throw new InternalServerError(errorMessage, {
      router: "vendor",
      routePath: "GetTerms",
    });
  }

  // Then handle the expected backend errors. All have a 400 status code.
  if (statusType !== 2) {
    const errorData = data as { code?: string };
    switch (errorData.code) {
      case "SHARED/RPC/LOGIN_REQUIRED":
        throw new LoginRequired("LOGIN_REQUIRED", {
          router: "vendor",
          routePath: "GetTerms",
        });
      case "SHARED/RPC/MISSING_PERMISSION":
        throw new MissingPermission("MISSING_PERMISSION", {
          router: "vendor",
          routePath: "GetTerms",
        });
      case "SHARED/RPC/UNMARSHAL_FAILURE":
        throw new UnmarshalFailure("UNMARSHAL_FAILURE", {
          router: "vendor",
          routePath: "GetTerms",
        });
      default:
        const error = new UnknownRPCError("An error occurred.", {
          code: errorData.code,
          status: response.status,
          data: errorData,
          router: "vendor",
          routePath: "GetTerms",
        });
        captureException(error);
        throw error;
    }
  }

  return data as IVendorGetTermsReturn;
};

export const useVendorGetTerms = (
  config?: SWRConfiguration,
): SWRResponse<IVendorGetTermsReturn, RPCError> => {
  return useSWR(
    "/v1/vendor/GetTerms",
    vendorGetTerms,
    config,
  );
};

type IRouteErrorOptions = Omit<IRPCErrorOptions, "status" | "router" | "routePath">;
