// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import * as gqlTypes from "~src/__generated__/graphql/types";

import {
  FieldValidationError,
  RPCError,
  IRPCErrorOptions,
} from "~src/shared/errors/taxonomy";

type ISharedErrorOptions = Omit<IRPCErrorOptions, "status">;

export class LoginRequired extends RPCError {
  constructor(message: string, options: ISharedErrorOptions) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/RPC/LOGIN_REQUIRED",
      expected: true,
      status: 401,
    });
  }
}

export class MissingPermission extends RPCError {
  constructor(message: string, options: ISharedErrorOptions) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/RPC/MISSING_PERMISSION",
      expected: true,
      status: 403,
    });
  }
}

export class UnmarshalFailure extends RPCError {
  constructor(message: string, options: ISharedErrorOptions) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/RPC/UNMARSHAL_FAILURE",
      expected: true,
      status: 400,
    });
  }
}
